import { Flex, Text, Image, Box } from "theme-ui";
import { ExitButton } from "./ExitButton";
import { BackButton } from "./BackButton";
import Logo from "../images/logo.svg";

export const Header: React.FunctionComponent<{
  showBack: boolean;
  onBack?: () => void;
  onClose: () => void;
  hideExitButton: boolean;
}> = ({ showBack, onBack, onClose, hideExitButton }) => (
  <Flex sx={{ justifyContent: "space-between", width: "100%", mb: "0.25em", px: 4, flexDirection:"row" }}>
    {showBack && <BackButton onBack={onBack} />}
    {!showBack && <Box width={23} px={"11px"}/>}
    <Flex sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Image src={Logo} width={23}  sx={{width: "23px", height: "23px"}}/>
      <Text sx={{ pl: 1 }}>Vital</Text>
    </Flex>
    {!hideExitButton && <ExitButton onClose={onClose} />}
    {hideExitButton && <Box />}
  </Flex>
);
