export type ErrorOrCloseBehaviour = "redirect" | undefined;

type TextFormInput = {
  type: "text";
  label: string;
  value: string;
  key: string;
};

type PasswordFormInput = {
  type: "password";
  label: string;
  value: string;
  key: string;
};

type SelectionFormInput = {
  type: "select";
  label: string;
  value: string;
  options: string[];
  names?: Record<string, string>;
  key: string;
};

export type FormInput = TextFormInput | SelectionFormInput | PasswordFormInput;

export interface FormComponents {
  instructions: string[];
  inputs: FormInput[];
}

export interface Device {
  name: string;
  description: string;
  oauth_url: string;
  logo: string;
  slug: string;
  auth_type: "password" | "oauth" | "team_oauth" | "email";
  form_components: FormComponents;
}

export interface ProviderMFARequest {
  method: string;
  hint: string;
}

export interface WizardProps {
  linkCompleted?: {
    redirectUrl: string
  };
  provider_mfa?: ProviderMFARequest;
}

export interface TokenContextProps {
  token: string;
  isMobile: boolean;
  isLoading: boolean;
  error: boolean;
  providers: Device[];
  UIState: "open" | "closed";
  state: null | "oauth_started" | "success" | "failed" | "authenticating";
  closeIframe: () => void;
}

export enum VITAL_EVENTS_V1 {
  CLOSE = "LINK_EVENT::CLOSE",
  SUCCESS = "LINK_EVENT::SUCCESS",
  FAILED = "LINK_EVENT::FAILED",
}

export enum VITAL_EVENTS_V2 {
  CONNECT_ERROR = "LINK_EVENT_V2::CONNECT_ERROR", // There has been an error in the link process
  CONNECT_SUCCESS = "LINK_EVENT_V2::CONNECT_SUCCESS", // The user has successfully connected a devcice.
  HANDOFF_SUCCESS = "LINK_EVENT_V2::HANDOFF_SUCCESS", // The user has exited Link after successfully linking an Item.
  HANDOFF_FAILED = "LINK_EVENT_V2::HANDOFF_FAILED", // The user has exited Link after failing to link an Item.
  EXIT = "LINK_EVENT_V2::EXIT", // The user has exited without completing the Link flow and the exit callback is fired.
}

export const extractFormComponentDefaults = (components?: FormComponents) => {
  if (!components || !components.inputs) {
    return {};
  }

  let defaults: Record<string, any> = {};

  for (const input of components.inputs) {
    if (input.type == "select") {
      defaults[input.key] = input.value;
    }
  }

  return defaults;
};
