import { ProviderMFARequest } from "../models";
import { getBaseUrl } from "./Config";

export class HTTPError extends Error {
  public status: number;
  public json?: any;

  constructor(message: string, status: number, json: any) {
    super(message)
    this.status = status;
    this.json = json;
  }

}

export interface TokenConfig {
  flow: "single_integration" | "multiple_integration";
  cancellation_url?: string;
}

export interface LinkOutcome {
  state: "success" | "error" | "pending_provider_mfa";
  error_type?: string;
  redirect_url?: string;
  provider_mfa?: ProviderMFARequest;
}

interface APIErrorDetails {
  redirect_url?: string;
}

interface APIErrorResponse {
  detail?: APIErrorDetails;
}

export class ApiClient {
  async checkResponseStatus(response: Response) {
    if (response.status >= 200 && response.status < 310) {
      return response;
    } else {
      const json: APIErrorResponse | null = await (response.json().catch(() => null));

      if (response.status == 401) {
        const redirectUrl = json?.detail?.redirect_url;

        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }

      let error = new HTTPError(response.statusText, response.status, json);
      throw error;
    }
  }

  async checkTokenIsValid(token: string, env: string, region: string) {
    return await this.fetch(
      "/link/token/isValid",
      "POST",
      { token: token, is_used: false },
      {
        "Content-Type": "application/json",
      },
      env,
      region
    );
  }

  async startAuth(
    link_token: string,
    provider: string,
    env: string,
    region: string
  ): Promise<Response> {
    const response = await this.fetch(
      "/link/start",
      "POST",
      { link_token, provider },
      {
        "Content-Type": "application/json",
      },
      env,
      region
    );
    return response;
  }

  async passwordAuth(
    username: string,
    password: string,
    provider: string,
    link_token: string,
    env: string,
    providerRegion: string | null,
    apiRegion: string
  ): Promise<LinkOutcome> {
    const response = await this.fetch(
      `/link/provider/password/${provider}`,
      "POST",
      { username, password, region: providerRegion },
      {
        "Content-Type": "application/json",
        "X-Vital-Link-Token": link_token,
      },
      env,
      apiRegion
    );
    return await response.json();
  }

  async completeProviderMFA(
    mfa_code: string,
    provider: string,
    link_token: string,
    env: string,
    apiRegion: string
  ): Promise<LinkOutcome> {
    const response = await this.fetch(
      `/link/provider/password/${provider}/complete_mfa`,
      "POST",
      { mfa_code },
      {
        "Content-Type": "application/json",
        "X-Vital-Link-Token": link_token,
      },
      env,
      apiRegion
    );
    return await response.json();
  }

  async emailAuth(
    email: string,
    provider: string,
    link_token: string,
    env: string,
    authType: "email",
    region: string,
    sourceRegion: string | null
  ): Promise<LinkOutcome> {
    const response = await this.fetch(
      `/link/provider/email/${provider}`,
      "POST",
      {
        email,
        region: sourceRegion ? sourceRegion : region,
      },
      {
        "Content-Type": "application/json",
        "X-Vital-Link-Token": link_token,
      },
      env,
      region
    );
    return await response.json();
  }

  async fetch(
    endpoint,
    method,
    body = null,
    headers = {},
    env = "prod",
    region = "us"
  ) {
    const BASE_URL = getBaseUrl(env, region);
    const url = `${BASE_URL}${endpoint}`;
    const options = {
      method: method,
      headers: {
        ...headers,
        "x-vital-link-timezone": Intl?.DateTimeFormat().resolvedOptions().timeZone
      },
      body: body ? JSON.stringify(body) : null,
      redirect: "follow",
    };
    // @ts-ignore
    return fetch(url, options)
      .then(this.checkResponseStatus)
      .then((response) => response);
  }
}

export const tokenIsInvalid = async (token, env = "prod", region = "us") => {
  const client = new ApiClient();
  try {
    const resp = await client.checkTokenIsValid(token, env, region);
    return resp;
  } catch (e) {
    return true;
  }
};

export const checkTokenConfig = async (token, env = "prod", region = "us"): Promise<[boolean, TokenConfig | null]> => {
  const client = new ApiClient();
  try {
    const resp = await client.checkTokenIsValid(token, env, region);
    const config = await resp.json();
    return [false, config as TokenConfig];
  } catch (e) {
    return [true, null];
  }
};

export const fetcher = (url, token) => {
  return fetch(url, { headers: { LinkToken: token } }).then((resp) =>
    resp.json()
  );
};
