import React from "react";
import { Box, Flex, Text, Image } from "theme-ui";
import { UseComboboxGetItemPropsOptions } from "downshift";
import { Device } from "../models";
import { IoChevronForward } from "react-icons/io5";

interface ListItemProps {
  getItemProps: (options: UseComboboxGetItemPropsOptions<Device>) => object;
  item: Device;
  index: number;
  highlightedIndex: Number;
  onClick: (device: Device) => void;
}

export const ListItem: React.FunctionComponent<ListItemProps> = ({
  getItemProps,
  item,
  index,
  highlightedIndex,
  onClick,
}) => {
  return (
    <li
      key={`${item}${index}`}
      {...getItemProps({ item, index })}
      onClick={() => onClick(item)}
    >
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          ":hover": {
            backgroundColor: "rgba(0,0,0,0.03)",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            borderBottom: "1px Solid rgba(0,0,0,0.03)",
            textAlign: "left",
            py: 2,
            mx: 4,
          }}
        >
          <Flex sx={{ alignItems: "center", justifyItems: "stretch", width: "full", gap: "0.8em" }}>
            <Image src={item.logo} height={"40em"} width={"40em"} sx={{borderRadius: 100, flexShrink: 0}}/>
            <Flex sx={{ flexDirection: "column", flexGrow: 2 }}>
              <Text
                sx={{
                  fontSize: 2,
                  textAlign: "left",
                  color: "black",
                  pt: 1,
                }}
              >
                {item.name}
              </Text>
              <Text
                sx={{
                  fontSize: 12,
                  textAlign: "left",
                  color: "gray",
                  pt: 1,
                }}
              >
                {item.description}
              </Text>
            </Flex>
            <Box as={IoChevronForward} sx={{height: "0.8em", width: "0.8em", flexShrink: 0}} />
          </Flex>
        </Box>
      </Flex>
    </li>
  );
};
